<script setup>
    const {app} = useConfig()
    // console.log(config)

</script>
<template>
    <div class="debug-overlay">
        <div class="debug-overlay__grid-wrapper" v-if="app.debug">
        <div class="debug-overlay__grid 2xl:container grid grid-cols-12 gap-x-8" >
            <div class="grid__column"></div>
            <div class="grid__column"></div>
            <div class="grid__column"></div>
            <div class="grid__column"></div>
            <div class="grid__column"></div>
            <div class="grid__column"></div>
            <div class="grid__column"></div>
            <div class="grid__column"></div>
            <div class="grid__column"></div>
            <div class="grid__column"></div>
            <div class="grid__column"></div>
            <div class="grid__column"></div>
        </div>
        </div>
    </div>
</template>
<style lang="scss">
@mixin colName(){

}
    .debug-overlay{
        position: fixed;
        z-index: 10000;
        width:100%;
        height:100vh;
        pointer-events: none;
        &__grid-wrapper{
            padding:0px 2rem;
            height:100%;
        }
        &__grid{
            margin:0px auto;
            counter-reset: number;
            // padding:0px 45px;
            column-gap:30px;
            // padding:0px 2rem;
            height: 100%;
            .grid__column{
                counter-increment: number;
                &::before{
                    
                    content:counter(number);
                    width: 100%;
                    height:100%;
                    display: block;
                    background-color:rgba($color: #ff00fb, $alpha: .25)
                }
            }
        }

    }
</style>